import { FC, ReactElement, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Icon } from '@common/atoms/Icon';
import { InternalLink, InternalLinkProps } from '@web/atoms/InternalLink';
import { Item } from '@web/molecules/MainDropdown/types';

import { MainDropdown } from '../MainDropdown';

import styles from './TabbedContent.module.scss';

export interface Tab {
    title: string;
    content: ReactElement;
    selected?: boolean;
}

export interface Props {
    title?: string;
    subtitle?: string | false;
    isPrimary?: boolean;
    isSlider?: boolean;
    tabs: Tab[];
    dropDownItems?: Item[];
    defaultDropDownItem?: Item;
    onChangeDropdown?: (selection: Item) => Promise<void>;
    link?: InternalLinkProps;
}

export const TabbedContent: FC<Props> = ({
    tabs,
    dropDownItems,
    defaultDropDownItem,
    isPrimary,
    isSlider,
    onChangeDropdown,
    subtitle: initialSubtitle,
    title,
    link,
}) => {
    const __actions = useTranslation('actions').t;

    const classes: string[] = [];
    classes.push(styles.TabbedContent);
    if (isPrimary) classes.push(styles.primary);

    // Set tab active based on slug
    let selectedTab = 0;
    tabs.forEach((tab, i) => {
        if (tab.selected) selectedTab = i;
    });
    const [activeTab, setActiveTab] = useState<number>(selectedTab);

    // Handle click action for slider
    const onClick = (type: string) => {
        // Return when first or last item has been reached
        if (activeTab === 0 && type === 'prev') return;
        if (activeTab === tabs.length) return;

        if (type === 'next') {
            setActiveTab(activeTab + 1);
        } else if (type === 'prev') {
            setActiveTab(activeTab - 1);
        }
    };

    const subtitle = tabs.length < 2 && initialSubtitle !== false ? tabs[0]?.title : initialSubtitle;
    const slider: string = isSlider ? styles.Slider : '';
    return (
        <section key={`tabbed-content-${title}`} className={classes.join(' ')}>
            {subtitle || title ? (
                <header>
                    <div>
                        {subtitle ? <h3>{subtitle}</h3> : null}
                        {title ? <h2>{title}</h2> : null}
                    </div>
                    {link ? (
                        <InternalLink {...link}>
                            {__actions`more`}
                            <Icon.right />
                        </InternalLink>
                    ) : null}
                    {dropDownItems?.length ? (
                        <MainDropdown
                            onChangeDropdown={onChangeDropdown}
                            items={dropDownItems || []}
                            defaultItem={defaultDropDownItem}
                        />
                    ) : null}
                </header>
            ) : null}
            {tabs.length > 1 ? (
                <>
                    <ul className={styles.tabs + ' inverted ' + slider}>
                        <div onClick={() => onClick('prev')} className={activeTab > 0 ? '' : styles.hidden}>
                            <Icon.left />
                        </div>
                        {tabs.map((tab, i) =>
                            tab.title ? (
                                <li
                                    key={i}
                                    className={activeTab === i ? styles.active : ''}
                                    onClick={() => setActiveTab(i)}
                                >
                                    {tab.title}
                                </li>
                            ) : null,
                        )}
                        <div
                            onClick={() => onClick('next')}
                            className={activeTab < tabs.length - 1 ? '' : styles.hidden}
                        >
                            <Icon.right />
                        </div>
                    </ul>
                </>
            ) : null}
            <div className={styles['tab-wrapper']}>{tabs[activeTab]?.content}</div>
        </section>
    );
};
